const hljs = require("highlight.js/lib/core");  // require only the core library
const moment = require('moment-timezone');
const codeSample = require('./codeSample');

require('./css/styles.scss');
require('highlight.js/styles/default.css');

// separately require languages
hljs.registerLanguage('php', require('highlight.js/lib/languages/php'));
document.querySelector('#code-sample pre').innerHTML = hljs.highlight('php', codeSample).value;

const refreshLocalTime = function () {
  const now = moment().tz('Europe/Moscow');
  document.querySelector('#local-time').textContent = now.format('LT (Z)');
};

// show local time
setInterval(refreshLocalTime, 60 * 1000);
refreshLocalTime();
